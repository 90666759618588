import React, { useState, useEffect } from 'react'
import { Fade, Zoom } from 'react-reveal'

import './style.scss'

// Mobile
import cover_bg from './asset/mobile/cover_bg.png'
import floral_decorative_mobile from './asset/mobile/floral_decorative_mobile.png'
// Website
import bg_langscape from './asset/website/bg_langscape.jpg'
import bg_website from './asset/website/bg_website.png'
import floral_decorative_website from './asset/website/floral_decorative_website.png'
import logogram from './asset/website/logogram.png'

// Example Call Component
{/* 
    <Splash 
        onOpen={setOpenInv}
        guest={guest}
        hashtag={"#BeMyCouple"}
        coupleName={"Couple & Name"}
        fullDate={"October 23, 2021"}
        day={30}
        month={12}
        srcImg={"https://res.cloudinary.com/weboosh/image/upload/v1638888052/sample_b04oen.webp"}
    /> 
*/}

const Splash = (props) => {
    const [timeLeft, setTimeLeft] = useState('');
    const { onOpen, coupleName, coupleName2, guest, hashtag, fullDate, day, month, dataCouple } = props

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            // setYear(new Date().getFullYear());
        }, 1000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
    });

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <div key={index}>
                <div className="interval-date font-g-r txt-size-1rem">
                    {timeLeft[interval]}
                </div>
                <div>
                    {interval}
                </div>
            </div>
        );
    });

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = new Date(`${month}/${day}/${year}`) - new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                Hours: Math.floor((difference / (1000 * 60 * 60)) % 24) + 19,
                Minutes: Math.floor((difference / 1000 / 60) % 60),
                Seconds: Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    };

    const onChange = () => {
        onOpen(true)
    }

    return (
        <div>
            <div className="container-header">
                <div className="container-image">
                    <img
                        className="image-header"
                        src={width < 769 ? dataCouple.gallery.splashmobile : dataCouple.gallery.splashweb}
                        loading="lazy"
                        alt="image"
                    />
                    <div className="container-text">
                        <Zoom><div className="text-header font-g-b txt-size-1rem">The Wedding of</div></Zoom>
                        {/* <Fade top><div className="text-header font-g-r txt-size-1rem">By the grace of God, we Invite you to share in their joy at wedding of</div></Fade> */}

                        {/* <Fade left><div className="text-header font-m-m txt-size-1rem">{ dataCouple.couple.groomParents }</div></Fade>
                        <Fade left><div className="text-header font-m-m txt-size-1rem">{ dataCouple.couple.brideParents }</div></Fade> */}

                        <Zoom><div className="text-header font-s txt-size-5rem" style={{ lineHeight: '66px' }}>{dataCouple.couple.groomSurname}</div></Zoom>
                        <Zoom><div className="text-header font-s txt-size-5rem" style={{ lineHeight: '66px' }}>{dataCouple.couple.brideSurname}</div></Zoom>
                        {/* <br></br> */}
                        <Zoom>
                            <img
                                style={ width < 769? {width: '22%'} : {width: '8%'}}
                                src={dataCouple.gallery.img14}
                                loading="lazy"
                                alt="icon"
                            />
                        </Zoom>
                        <Zoom><div className="text-header font-g-r txt-size-1rem">{dataCouple.moment.fullDate}</div></Zoom>
                        <Fade right>
                            <div className="text-header font-g-r txt-size-1rem">

                                <strong className="font-g-b">
                                    {
                                        guest ? `Dear ${guest},` : ""
                                    }
                                </strong>
                            </div>
                        </Fade>
                    </div>
                    <div className="container-date">
                        {/*<Zoom><div className="button-open" onClick={() => onChange()}>Open Invitation</div></Zoom>
                        <br></br>*/}
                        <Fade bottom><div className="text-header font-g-r txt-size-1rem">{dataCouple.couple.hashtag ? dataCouple.couple.hashtag : ""}</div></Fade>
                    </div>

                    {/* <div className="container-date-countdown">
                        {timerComponents.length ? timerComponents : null}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Splash
