import React, { lazy, useEffect, useState, Suspense, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/Ultimate/action'

import './style.scss'

import dataCouple from '../../../data/arwin.json'
import AudioPlayer from '../../player/music/music'
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';
import {
    home,
    couple,
    gallery,
    calender,
    wish,
} from '../../../asset'

// Mobile
import cover_bg from './asset/mobile/cover_bg.png'
import floral_decorative_mobile from './asset/mobile/floral_decorative_mobile.png'
// Website
import bg_langscape from './asset/website/bg_langscape.jpg'
import bg_website from './asset/website/bg_website.png'
import floral_decorative_website from './asset/website/floral_decorative_website.png'
import logogram from './asset/website/logogram.png'
import canon_in_d from './asset/canon_in_d.mp3'

import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faMap, faPaperPlane, faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { HeartFilled, InstagramOutlined } from '@ant-design/icons'

// import Splash from './splash.js'
import Splash from './splash'
import OurStory from '../../SlickSlider/OurStory'
import Meta from "../../Meta.js"
import { forEach } from 'lodash';
const Footer = lazy(() => import('./footer.js'))

const Ultimate = (props) => {
    const { Ultimate, fetchWishesClient, postWishClient, fetchWishes, postWish, fetchWishesByID } = props
    let { isLoading, wishesList } = Ultimate

    const [width, setWidth] = useState(window.innerWidth);

    let { guest } = useParams()
    let guestFormat = ''
    if (guest) {
        guestFormat = guest.split('+').join(' ')
    }

    const content = useRef(null)
    const homeRef = useRef(null)
    const coupleRef = useRef(null)
    const eventRef = useRef(null)
    const galleryRef = useRef(null)
    const quoteRef = useRef(null)
    const wishRef = useRef(null)

    const [openInv, setOpenInv] = useState(false)
    const [audioSrc, setAudioSrc] = useState('')
    const [playing, setPlaying] = useState(false);
    const [name, setName] = useState("")
    const [wishes, setWishes] = useState("")
    const [isAttend, setIsAttend] = useState("0")
    const [person, setPerson] = useState("0")
    const [openModal, setOpenModal] = useState(false)

    // if (openModal) {
    //     document.body.style.overflow = 'hidden';
    // } else {
    //     document.body.style.overflow = 'unset';
    // }

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            // setYear(new Date().getFullYear());
        }, 1000);
        // Clear timeout if the component is unmounted
        
        // setPlaying(false)
        return () => clearTimeout(timer);
    })

    useEffect(() => {
        window.scrollTo(0, 0) //Auto scroll top setiap buka
        setAudioSrc(canon_in_d)
        

        setTimeout(() => {
            let arr = [1, 2, 3]
            arr.forEach((obj, idx) => {
                setPlaying(!playing)
            })
        }, 2000)

        // fetchWishes()
        // fetchWishesClient(dataCouple.couple.id)
        fetchWishesByID(dataCouple.couple.id)
        
        // Check size width
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])


    useEffect(() => {
        if (content.current) {
            // setPlaying(true)
            // content.current.scrollIntoView({
            //     behavior: 'smooth'
            // });
        }
    }, [openInv])

    const onChangeRef = (ref) => {
        switch (ref) {
            case "home":
                if (homeRef.current) {
                    homeRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "couple":
                if (coupleRef.current) {
                    coupleRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "event":
                if (eventRef.current) {
                    eventRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "gallery":
                if (galleryRef.current) {
                    galleryRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "quote":
                if (quoteRef.current) {
                    quoteRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "wish":
                if (wishRef.current) {
                    wishRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            default:
                break;
        }
    }

    const onInit = () => {
        console.log('E-Invitation by Weboosh has been initialized');
    };

    // const onClickPlay = () => {
    //     setPlaying(!playing)
    // }

    const onSubmit = (e) => {
        e.preventDefault()
        if (name !== "" &&
            wishes !== ""
            // isAttend !== "0" &&
            // person !== "0"
        ) {
            let data = {
                id: dataCouple.couple.id,
                name: name, 
                wishes: wishes,
                isAttend: isAttend,
                totalPerson: person,
            }
            postWishClient(data)
            // postWish(data)
            setName("")
            setWishes("")
            setIsAttend("0")
            setPerson("0")
        } else {
            alert("Please Fill Your Name and Wedding Wish First")
        }
    }

    const Popup = () => {

        return (
            <div className='popup'>
                <span className="close" onClick={() => setOpenModal(!openModal)}>&times;</span>
                <div className='popup_inner'>
                    <p className="txt-size-4rem font-s">Wedding Gift</p>
                    <p className="font-g-r">Having you with us on our special day is a true blessing. However, if you’d like to shower us with more gifts, we warmly welcome them!</p>

                    <p className="txt-size-2rem font-g-b">Bank { dataCouple.account.bank1 }</p>
                    <p className="txt-size-2rem font-g-b">A/n {dataCouple.couple.brideName} {dataCouple.account.rekening1}</p>
                    
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(dataCouple.account.rekening1)
                        alert(`Copied to clipboard: ${dataCouple.account.rekening1}`)
                    }}>
                        Copy to Clipboard
                    </button>
                    {/* <br /><br />
                    <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={dataCouple.account.qrCode2}
                            alt="Rekening"
                        />
                    </div>
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(dataCouple.account.rekening2)
                        alert(`Copied to clipboard: ${dataCouple.account.rekening2}`)
                    }}>
                        Copy to Clipboard
                    </button> */}
                </div>
            </div>
        )
    }

    const MetaData = () => {
        return (
            <Meta
                metaTitle={dataCouple.couple.fullName}
                description={"The Wedding of " + dataCouple.couple.fullName}
                keywords={"The Wedding of " + dataCouple.couple.fullName}
                image={dataCouple.gallery.img9}
                url={dataCouple.website.url}
            />
        )
    }

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = new Date(`${dataCouple.moment.receptionMonth}/${dataCouple.moment.receptionDay}/${year}`) - new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                Minutes: Math.floor((difference / 1000 / 60) % 60),
                Seconds: Math.floor((difference / 1000) % 60)
            }
        }
        return timeLeft;
    }
    const [timeLeft, setTimeLeft] = useState('');
    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval, index) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <div key={index}>
                <div className="interval-date font-g-r txt-size-1rem">
                    {timeLeft[interval]}
                    <br></br>
                    <span className="txt-size-075rem">
                        {interval}
                    </span>
                </div>
                {/* <div>
                    {interval}
                </div> */}
            </div>
        );
    });

    return (
        <div id="ultimate-page-arwin">
            {/* <MetaData /> */}
            <div ref={homeRef} className="container-body type-purple-70 type-cream-10">
                <Fragment>
                    <Splash 
                        onOpen={setOpenInv}
                        guest={guestFormat}
                        coupleName={dataCouple.couple.fullName}
                        fullDate={dataCouple.moment.fullDate}
                        day={dataCouple.moment.receptionDay}
                        month={dataCouple.moment.receptionMonth}
                        dataCouple={dataCouple}
                    />
                    <div ref={content} className="content">
                        <div className="container-header-count">
                            <div className="container-image">
                                <img
                                    // className="image-header"
                                    src={dataCouple.gallery.img10}
                                    loading="lazy"
                                    alt="image"
                                />

                                <div className="container-date-countdown">
                                    {timerComponents.length ? timerComponents : null}
                                </div>
                            </div>
                        </div>

                        <div className="container-banners bg-banner">
                            <div className="container-content-banner">
                                <div className="container-icon-banner">
                                    <Zoom>
                                        <img
                                            className="icon-banner"
                                            src={dataCouple.gallery.img13}
                                            loading="lazy"
                                            alt="icon"
                                        />
                                    </Zoom>
                                   
                                </div>
                                <Zoom>
                                    <div className="text-banner">
                                        <br></br>
                                        <p className="txt-size-1rem font-g-b">IS GETTING MARRIED</p>
                                        <Zoom>
                                            {/* className="icon-banner" */}
                                            <img
                                                style={{width: '40%'}}
                                                src={dataCouple.gallery.img14}
                                                loading="lazy"
                                                alt="icon"
                                            />
                                        </Zoom>
                                        <br></br><br></br>
                                        <Zoom>
                                            <p className="txt-size-1rem font-g-r">{dataCouple.header.subTitle}</p>
                                        </Zoom>

                                        {/* <p className="txt-size-1rem font-g-r">{dataCouple.verse.one}</p>
                                        <p className="txt-size-075rem font-m-m">{dataCouple.verse.oneDesc}</p> */}
                                    </div>
                                </Zoom>
                                {/* <div className="container-image2-banner">
                                    <img
                                        className="image-banner"
                                        src={elviamuda1}
                                        loading="lazy"
                                        alt="einvitation"
                                    />
                                </div> */}
                                <div className="container-image3-banner">
                                    <Fade>
                                        <img
                                            className="image3-banner"
                                            src={dataCouple.gallery.img15}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        
                        <div ref={coupleRef} className="container-bride" style={width < 769 ? {} : { display: 'flex'}}>
                            {/* <div className="container-icon-bride">
                                <Zoom>
                                    <img
                                        className="icon-bride"
                                        src={ilustrasi2}
                                        loading="lazy"
                                        alt="icon"
                                    />
                                </Zoom>
                            </div> */}
                            {/* <div className="title-bride">
                                <Fade bottom>
                                    <div className="txt-size-2rem font-g-b">The Groom & Bride</div>
                                </Fade>
                            </div> */}
                            {/* <Zoom>
                                <div className="container-bride-name">
                                    <div className="font-s txt-size-2rem">{dataCouple.couple.groomName}</div>
                                    <a target="_blank" rel="noopener noreferrer" href={dataCouple.couple.groomUrlIg}>
                                        <div className="row justify-content-center align-items-center font-g-r">
                                            <InstagramOutlined style={{ fontSize: '15px', padding: '15px 4px' }} /> @jonatantobing
                                        </div>
                                    </a>
                                    <div className="font-g-r">{dataCouple.couple.groomParents}</div>
                                </div>
                            </Zoom> */}
                            <div className="container-bride-photo">
                                <Fade left>
                                    <img
                                        className="bride-photo"
                                        src={dataCouple.gallery.img1}
                                        loading="lazy"
                                        alt="einvitation"
                                    />
                                </Fade>
                                <div className="container-groom-name-inside">
                                    <div className="font-s txt-size-3rem">{dataCouple.couple.groomName}</div>
                                    <div className="font-g-b">{dataCouple.couple.groomParents}</div>
                                    <a target="_blank" rel="noopener noreferrer" href={dataCouple.couple.groomUrlIg}>
                                        <div className="font-g-r">
                                            <InstagramOutlined style={{ fontSize: '15px', padding: '15px 4px' }} /> {dataCouple.couple.groomUsernameIg}
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="container-bride-photo">
                                <Fade right>
                                    <img
                                        className="bride-photo"
                                        src={dataCouple.gallery.img2}
                                        loading="lazy"
                                        alt="einvitation"
                                    />
                                </Fade>

                                <div className="container-bride-name-inside">
                                    <div className="font-s txt-size-3rem">{dataCouple.couple.brideName}</div>
                                    <div className="font-g-b">{dataCouple.couple.brideParents}</div>
                                    <a target="_blank" rel="noopener noreferrer" href={dataCouple.couple.brideUrlIg}>
                                        <div className="font-g-r">
                                            <InstagramOutlined style={{ fontSize: '15px', padding: '15px 4px 0 0' }} /> { dataCouple.couple.brideUsernameIg }
                                        </div>
                                    </a>
                                </div>
                            </div>
                            {/* <Zoom>
                                <div className="container-bride-name">
                                    <div className="font-s txt-size-2rem">{dataCouple.couple.brideName}</div>
                                    <a target="_blank" rel="noopener noreferrer" href={dataCouple.couple.brideUrlIg}>
                                        <div className="row justify-content-center align-items-center font-g-r">
                                            <InstagramOutlined style={{ fontSize: '15px', padding: '15px 4px' }} /> @elviageralda
                                        </div>
                                    </a>
                                    <div className="font-g-r">{dataCouple.couple.brideParents}</div>
                                </div>
                            </Zoom> */}
                        </div>

                        <div ref={eventRef} className="container-event">
                            {/* <div style={{ transform: "rotate(180deg)", paddingBottom: '14px'}}>
                                <Fade>
                                    <img
                                        className="image3-banner"
                                        src={dataCouple.gallery.img15}
                                        loading="lazy"
                                        alt="einvitation"
                                    />
                                </Fade>
                            </div> */}

                            <div className="container-image3-event">
                                <Fade>
                                    <img
                                        className="image3-banner"
                                        src={dataCouple.gallery.img15}
                                        loading="lazy"
                                        alt="einvitation"
                                    />
                                </Fade>
                            </div>
                            

                            <Fade>
                                <p className="txt-size-4rem font-s">Save The Date</p>
                                <p className="txt-size-2rem font-g-r">{dataCouple.moment.fullDate}</p>
                            </Fade>
                            <Zoom>
                                {/* className="icon-banner" */}
                                <img
                                    style={{ width: '40%' }}
                                    src={dataCouple.gallery.img14}
                                    loading="lazy"
                                    alt="icon"
                                />
                            </Zoom>
                            <Zoom>
                                <div className="content-event">
                                    <p className="font-g-b txt-size-3rem" >Holy Matrimony</p>
                                    {/* <FontAwesomeIcon icon={faCalendarAlt} />
                                    <p className="font-g-r">{dataCouple.moment.fullDate}</p> */}
                                    {/* <div className="line"></div>
                                    <p className="font-g-r">{dataCouple.moment.holyMatrimonyTime}</p> */}

                                    {/* <FontAwesomeIcon icon={faMap} /> */}
                                    <p className="txt-size-15rem font-g-b border-line">At {dataCouple.location.holyMatrimonyTime} WIB</p>
                                    <p className="txt-size-15rem font-g-b">{dataCouple.location.holyMatrimony}</p>
                                    <p className="font-g-b">{dataCouple.location.holyMatrimonyDetails}</p>
                                    <br></br>
                                    <div className="add-google">
                                        <a target="_blank" rel="noopener noreferrer" href={dataCouple.moment.holyMatrimonyCalendar}>
                                            VIEW MAPS
                                        </a>
                                    </div>
                                </div>
                            </Zoom>
                        </div>
                        <div className="container-event">
                            <br></br>
                            <br></br>
                            <Zoom>
                                <div className="content-event">
                                    <p className="font-g-b txt-size-3rem">The Wedding Day</p>
                                    {/* <FontAwesomeIcon icon={faCalendarAlt} />
                                    <p className="font-g-r">{dataCouple.moment.fullDate}</p>
                                    <div className="line"></div>
                                    <p className="font-g-r">{dataCouple.moment.receptionTime}</p> */}

                                    {/* <FontAwesomeIcon icon={faMap} /> */}
                                    <p className="txt-size-15rem font-g-b border-line">At {dataCouple.location.receptionTime} WIB</p>
                                    <p className="txt-size-15rem font-g-b">{dataCouple.location.reception}</p>
                                    <p className="font-g-b">{dataCouple.location.receptionDetails}</p>
                                    <br></br>
                                    <div className="add-google">
                                        <a target="_blank" rel="noopener noreferrer" href={dataCouple.moment.receptionCalendar}>
                                            VIEW MAPS
                                        </a>
                                    </div>
                                </div>
                                {/* <br /><br />
                                <div className="add-google">
                                    <a target="_blank" rel="noopener noreferrer" href={dataCouple.moment.holyMatrimonyCalendar}>                                                
                                        ADD TO GOOGLE CALENDAR
                                    </a>
                                </div> */}
                            </Zoom>
                            <br /><br />
                            <div>
                                <Fade>
                                    <img
                                        className="image3-banner"
                                        src={dataCouple.gallery.img15}
                                        loading="lazy"
                                        alt="einvitation"
                                    />
                                </Fade>
                            </div>
                        </div>
                        
                        <div ref={galleryRef}></div>
                            
                        {/* <div className="container-video">
                            <iframe
                                title="Legend and Renata"
                                className="video"
                                src="https://www.youtube.com/embed/kH7wlLOQMNM"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen="allowFullScreen">
                            </iframe>
                        </div> */}
                        <div className="container-gallery">
                            <br /><br />
                            <Zoom>
                                <div className="container-text-video txt-size-4rem font-s">
                                Our Gallery
                                </div>
                            </Zoom>

                            <div className="lg-custom-thumbnails">
                                <Zoom>
                                    <a className="gallery-item gallery-item--1">
                                        <img className="gallery-img" alt="Arwin & Jenny" src={dataCouple.gallery.img12} data-src={dataCouple.gallery.img12} loading="lazy" />
                                    </a>
                                </Zoom>
                                <Zoom>
                                    <a className="gallery-item gallery-item--2">
                                        <img className="gallery-img" alt="Arwin & Jenny" src={dataCouple.gallery.img3} data-src={dataCouple.gallery.img3} loading="lazy" />
                                    </a>
                                </Zoom>
                                <Zoom>
                                    <a className="gallery-item gallery-item--3">
                                        <img className="gallery-img" alt="Arwin & Jenny" src={dataCouple.gallery.img4} data-src={dataCouple.gallery.img4} loading="lazy" />
                                    </a>
                                </Zoom>
                                <Zoom>
                                    <a className="gallery-item gallery-item--4">
                                        <img className="gallery-img" alt="Arwin & Jenny" src={dataCouple.gallery.img5} data-src={dataCouple.gallery.img5} loading="lazy" />
                                    </a>
                                </Zoom>
                                <Zoom>
                                    <a className="gallery-item gallery-item--5">
                                        <img className="gallery-img" alt="Arwin & Jenny" src={dataCouple.gallery.img6} data-src={dataCouple.gallery.img6} loading="lazy" />
                                    </a>
                                </Zoom>
                                <Zoom>
                                    <a className="gallery-item gallery-item--6">
                                        <img className="gallery-img" alt="Arwin & Jenny" src={dataCouple.gallery.img7} data-src={dataCouple.gallery.img7} loading="lazy" />
                                    </a>
                                </Zoom>
                                <Zoom>
                                    <a className="gallery-item gallery-item--7">
                                        <img className="gallery-img" alt="Arwin & Jenny" src={dataCouple.gallery.img8} data-src={dataCouple.gallery.img8} loading="lazy" />
                                    </a>
                                </Zoom>
                            </div>
                        </div>
                        
                        <div ref={quoteRef} className="container-slider">
                            <Fade>
                                <div className="container-slider-photo">
                                    <img
                                        className="slide-photo"
                                        src={dataCouple.gallery.img11}
                                        loading="lazy"
                                        alt="einvitation"
                                    />
                                </div>
                            </Fade>
                        </div>
                        
                        <div className="container-quotes">
                            {/* <div className="container-image-quotes">
                                <img
                                    className="image-quotes"
                                    src={elviamuda2}
                                    loading="lazy"
                                    alt="einvitation"
                                />
                            </div> */}
                            <Zoom>
                                <div className="container-text font-g-r txt-size-1rem" style={{ lineHeight: '30px' }}>
                                    {dataCouple.verse.one}
                                </div>
                                <div className="container-text font-g-r txt-size-1rem" style={{ lineHeight: '30px' }}>
                                    {dataCouple.verse.oneDesc}
                                </div>
                            </Zoom>
                            <br></br>
                            <Zoom>
                                <img
                                    style={{ width: '40%' }}
                                    src={dataCouple.gallery.img14}
                                    loading="lazy"
                                    alt="icon"
                                />
                            </Zoom>
                            <br /><br />
                        </div>
                        
                        {/* <br />
                        <Zoom>
                            {
                                <OurStory 
                                    data={dataCouple.story}
                                />
                            }
                        </Zoom> */}
                        
                        <div className="container-gift">
                            <br></br>
                            <div style={{width: '80%'}}>
                                <Zoom>
                                    <p className="txt-size-4rem dark-brown-txt font-s">Wedding Gift</p>
                                </Zoom>
                                <Zoom>
                                    <p className="font-g-r">Having you with us on our special day is a true blessing. However, if you’d like to shower us with more gifts, we warmly welcome them!</p>
                                </Zoom>
                                <br></br>
                                <Zoom>
                                    <div className="btn-gift txt-size-1rem">
                                        <button className="btn-gift-style" onClick={() => setOpenModal(!openModal)}>BANK BCA</button>
                                    </div>
                                </Zoom>
                            </div>
                            <br /><br />
                        </div>

                        {
                            openModal ?
                                <Popup />
                            : null
                        }
                        
                        <div ref={wishRef} className="container-wishes">
                            <br /><br />
                            <Zoom>
                                <p className="txt-size-4rem font-s">Wedding Wish</p>
                                <form onSubmit={(e) => onSubmit(e)}>
                                    <input placeholder="Name" value={name} type="text" maxLength="50" required onChange={(e) => setName(e.target.value)} />
                                    <textarea placeholder="Wedding Wish" value={wishes} type="text" maxLength="500" rows="4" required onChange={(e) => setWishes(e.target.value)} />
                                    <div className="add-wishes">
                                        <button className="btn-wishes-style txt-size-1rem">
                                            {
                                                isLoading ? "Sending..." : "SEND WISH"
                                            }
                                        </button>
                                    </div>
                                </form>
                            </Zoom>
                            <br /><br />
                            <Zoom>
                                {
                                    wishesList.length > 0 ? 
                                        <div className="container-wishes-list">
                                            {
                                                wishesList.map((data, index) => (
                                                    <div key={index}>
                                                        <Fade>
                                                            <div>
                                                                <FontAwesomeIcon icon={faPaperPlane} />
                                                            </div>
                                                            <div className="content">
                                                                <p className="name font-g-b txt-size-075rem">{data.name}</p>
                                                                <p className="font-g-r txt-size-075rem">{data.wishes}</p>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                ))
                                            }
                                        </div>  
                                    : <div></div>
                                }
                            </Zoom>
                        </div>

                        <div className="container-thankyou" style={width < 769 ? { height: '80vh' } : { height: '140vh' }}>
                            <div 
                                className="container-image-thankyou"
                                >
                                {/* style={width < 769 ? {} : { height: '888px' }} */}
                                {/* style={width < 769 ? { objectFit: 'cover' } : { objectFit: 'cover'}} */}
                                <img
                                    className="image-thankyou"
                                    src={dataCouple.gallery.img9}
                                    loading="lazy"
                                    alt="einvitation"
                                />

                                <div className='text-inside-image'>
                                    <HeadShake>
                                        <div className="container-text-thankyou txt-size-1rem font-g-r">Your presence would mean the world to us!</div>
                                    </HeadShake>

                                    {/* className="image-thankyou-inside" */}
                                    <img
                                        className="image-thankyou-inside"
                                        src={dataCouple.gallery.img16}
                                        loading="lazy"
                                        alt="einvitation"
                                    />
                                    {/* <Zoom>
                                        <div className="container-text-thankyou font-s txt-size-35rem" style={{ lineHeight: '70px' }}>{dataCouple.couple.fullName}</div>
                                    </Zoom> */}
                                </div>
                            </div>
                        </div>
                        {/* Sampai sini */}
                        {/* <div className="font-g-r" style={{ display: 'none' }}>
                            <AudioPlayer src={audioSrc} onPlay={playing} />
                        </div> */}

                        <a className="music" onClick={() => setPlaying(!playing)}>
                            {
                                playing ?
                                    <Fragment>
                                        <div className="font-g-r" style={{ display: 'none' }}>
                                            <AudioPlayer src={audioSrc} onPlay={playing} />
                                        </div>
                                        <FontAwesomeIcon icon={faMusic} className="my-music" />
                                    </Fragment>
                                : 
                                    <FontAwesomeIcon icon={faVolumeMute} className="my-music" />
                            }
                        </a>

                        <Suspense fallback={<div>Loading...</div>}>
                            <Footer />
                        </Suspense>
                        {/* <div className="menu-sticky">
                            <div className="container-menu txt-size-075rem font-g-r white-txt">
                                <div onClick={() => onChangeRef("home")}>
                                    <img src={home} alt="Index" loading="lazy" />
                                    <div>HOME</div>
                                </div>
                                <div onClick={() => onChangeRef("couple")}>
                                    <img src={couple} alt="Index" loading="lazy" />
                                    <div>COUPLE</div>
                                </div>
                                <div onClick={() => onChangeRef("event")}>
                                    <img src={calender} alt="Index" loading="lazy" />
                                    <div>EVENT</div>
                                </div>
                                <div onClick={() => onChangeRef("gallery")}>
                                    <img src={gallery} alt="Index" loading="lazy" />
                                    <div>GALLERY</div>
                                </div>
                                <div onClick={() => onChangeRef("quote")}>
                                    <img src={bible} alt="Index" loading="lazy" />
                                    <div>VERSE</div>
                                </div>
                                <div onClick={() => onChangeRef("wish")}>
                                    <img src={wish} alt="Index" loading="lazy" />
                                    <div>WISH</div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    
                </Fragment>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Ultimate: state.Ultimate
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ultimate);
