import React, { lazy, useEffect, useState, Suspense, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../redux/Ultimate/action.js'

import './style.scss'

import dataCouple from '../../../data/cindy.json'
import AudioPlayer from '../../player/music/music.js'
import { Fade, Zoom } from 'react-reveal';
import HeadShake from 'react-reveal/HeadShake';
import {
    jingga1,
    jingga2,
    jinggamuda1,
    jinggamuda2,

    platinum1,
    platinum2,
    platinum3,
    platinum5,
    ilustrasi1,
    ilustrasi2,

    home,
    couple,
    gallery,
    calender,
    wish,
    quote,
    bible,
    niki,

    logoWhite,
    logoEinvitation
} from '../../../asset/index.js'
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faMap, faPaperPlane, faMusic, faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { HeartFilled, InstagramOutlined } from '@ant-design/icons'

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import Splash from './splash.js'
// import Splash from '../../splashSimple'
import OurStory from '../../SlickSlider/OurStory/index.js'
import Meta from "../../Meta.js"
const Footer = lazy(() => import('../../layout/Footer/ultimate/ultimate.js'))

const Ultimate = (props) => {
    const { Ultimate, fetchWishesByID, fetchWishesClient, postWishClient, fetchWishes, postWish } = props
    let { isLoading, wishesList } = Ultimate

    let { guest } = useParams();
    let guestFormat = ''
    if (guest){
        guestFormat = guest.split('+').join(' ')
    }

    const content = useRef(null)
    const homeRef = useRef(null)
    const coupleRef = useRef(null)
    const eventRef = useRef(null)
    const galleryRef = useRef(null)
    const quoteRef = useRef(null)
    const wishRef = useRef(null)

    const [openInv, setOpenInv] = useState(false)
    const [audioSrc, setAudioSrc] = useState('')
    const [playing, setPlaying] = useState(false);
    const [name, setName] = useState("")
    const [wishes, setWishes] = useState("")
    const [isAttend, setIsAttend] = useState("0")
    const [person, setPerson] = useState("0")
    const [openModal, setOpenModal] = useState(false)

    // if (openModal) {
    //     document.body.style.overflow = 'hidden';
    // } else {
    //     document.body.style.overflow = 'unset';
    // }

    useEffect(() => {
        window.scrollTo(0, 0) //Auto scroll top setiap buka
        setAudioSrc(niki)
        setPlaying(true)
        // fetchWishes()
        // fetchWishesClient(dataCouple.couple.id)
        fetchWishesByID(dataCouple.couple.id)
    }, [])

    useEffect(() => {
        if (content.current) {
            setPlaying(true)
            content.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [openInv])

    const onChangeRef = (ref) => {
        switch (ref) {
            case "home":
                if (homeRef.current) {
                    homeRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "couple":
                if (coupleRef.current) {
                    coupleRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "event":
                if (eventRef.current) {
                    eventRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "gallery":
                if (galleryRef.current) {
                    galleryRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "quote":
                if (quoteRef.current) {
                    quoteRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            case "wish":
                if (wishRef.current) {
                    wishRef.current.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
                break;
            default:
                break;
        }
    }

    const onInit = () => {
        console.log('E-Invitation by Weboosh has been initialized');
    };

    const onSubmit = (e) => {
        e.preventDefault()
        
        if (name !== "" && 
            wishes !== "" && 
            isAttend !== "0" && 
            person !== "0"
        ) {
            let data = {
                id: dataCouple.couple.id,
                name: name,
                wishes: wishes,
                isAttend: isAttend,
                totalPerson: person,
            }
            postWishClient(data)
            // postWish(data)
            setName("")
            setWishes("")
            setIsAttend("0")
            setPerson("0")
        } else {
            alert("Please Fill Your Name, Wedding Wish, Attendance and Guest First")
        }
    }

    const Popup = () => {

        return (
            <div className='popup'>
                <span className="close" onClick={() => setOpenModal(!openModal)}>&times;</span>
                <div className='popup_inner'>
                    <p className="txt-size-2rem font-a-b">Digital Gift</p>
                    <p className="font-m-r">{dataCouple.account.desc2}</p>

                    {/* <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={dataCouple.account.qrCode1}
                            alt="Rekening"
                        />
                    </div> */}
                    <p className="font-m-r">{dataCouple.account.bank1} {dataCouple.account.rekening1}</p>
                    <p className="font-m-r">a/n {dataCouple.couple.brideName}</p>
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(dataCouple.account.rekening1)
                        alert(`Copied to clipboard: ${dataCouple.account.rekening1}`)
                    }}>
                        Copy to Clipboard
                    </button>
                    {/* <br />
                    <div className="popup-img">
                        <img
                            className="popup-img-content"
                            src={dataCouple.account.qrCode2}
                            alt="Rekening"
                        />
                    </div>
                    <button className="btn-popup" onClick={() => {
                        navigator.clipboard.writeText(dataCouple.account.rekening2)
                        alert(`Copied to clipboard: ${dataCouple.account.rekening2}`)
                    }}>
                        Copy to Clipboard
                    </button> */}
                </div>
            </div>
        )
    }

    const MetaData = () => {
        return (
            <Meta
                metaTitle={dataCouple.couple.fullName}
                description={"The Wedding of " + dataCouple.couple.fullName}
                keywords={"The Wedding of " + dataCouple.couple.fullName}
                image={dataCouple.gallery.img9}
                url="https://einvitation.weboosh.id/kevincindy"
            />
        )
    }

    return (
        <div id="ultimate-page-custom">
            <MetaData />
            <div ref={homeRef} className="container-body type-blue-dennis type-cream-dennis">
                <Splash
                    onOpen={setOpenInv}
                    guest={guestFormat}
                    coupleName={dataCouple.couple.fullName}
                    fullDate={dataCouple.moment.fullDate}
                    day={dataCouple.moment.receptionDay}
                    month={dataCouple.moment.receptionMonth}
                    srcImg={dataCouple.gallery.img11}
                    dataCouple={dataCouple}
                />
                {
                    openInv ?
                        <Fragment>
                            <div ref={content} className="content">
                                <div className="container-banners">
                                    {/* <div className="container-image-banner">
                                        <img
                                            className="image-banner"
                                            src={jingga1}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div> */}
                                    <div className="container-content-banner">
                                        <div className="container-icon-banner">
                                            <Zoom>
                                                <img
                                                    className="icon-banner"
                                                    src={ilustrasi1}
                                                    loading="lazy"
                                                    alt="icon"
                                                />
                                            </Zoom>
                                        </div>
                                        <Zoom>
                                            <div className="text-banner">
                                                <p className="txt-size-1rem font-m-r">{dataCouple.verse.one}</p>
                                                <p className="txt-size-075rem font-m-m">{dataCouple.verse.oneDesc}</p>
                                            </div>
                                        </Zoom>
                                        <div className="container-image2-banner">
                                            <img
                                                className="image-banner"
                                                src={jinggamuda1}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                        <div className="container-image3-banner">
                                            <Fade>
                                                <img
                                                    className="image3-banner"
                                                    src={dataCouple.gallery.img15}
                                                    loading="lazy"
                                                    alt="einvitation"
                                                />
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                                <div ref={coupleRef} className="container-bride">
                                    <div className="container-icon-bride">
                                        <Zoom>
                                            <img
                                                className="icon-bride"
                                                src={ilustrasi2}
                                                loading="lazy"
                                                alt="icon"
                                            />
                                        </Zoom>
                                    </div>
                                    <div className="title-bride">
                                        <Fade bottom>
                                            <div className="txt-size-2rem font-a-b">The Groom & Bride</div>
                                        </Fade>
                                    </div>
                                    <Zoom>
                                        <div className="container-bride-name">
                                            <div className="font-ar txt-size-3rem">{dataCouple.couple.groomName}</div>
                                            {/* <div className="font-m-r">{dataCouple.couple.groomParents}</div> */}
                                            <div className="font-m-r">{dataCouple.couple.groomChildTh}</div>
                                            <div className="font-m-r">{dataCouple.couple.groomFather}</div>
                                            <div className="font-m-r">&</div>
                                            <div className="font-m-r">{dataCouple.couple.groomMother}</div>
                                            <a target="_blank" rel="noopener noreferrer" href={dataCouple.couple.groomUrlIg}>
                                                <div className="row justify-content-center align-items-center font-m-r">
                                                    <InstagramOutlined style={{ fontSize: '15px', padding: '15px 4px' }} /> {dataCouple.couple.groomUsernameIg}
                                                </div>
                                            </a>
                                        </div>
                                    </Zoom>
                                    <div className="container-bride-photo">
                                        <Fade left>
                                            <img
                                                className="bride-photo"
                                                src={dataCouple.gallery.img32}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </Fade>
                                    </div>
                                    <br /><br />
                                    <div className="container-bride-photo">
                                        <Fade right>
                                            <img
                                                className="bride-photo"
                                                src={dataCouple.gallery.img31}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </Fade>
                                    </div>
                                    <Zoom>
                                        <div className="container-bride-name">
                                            <div className="font-ar txt-size-3rem">{dataCouple.couple.brideName}</div>
                                            {/* <div className="font-m-r">{dataCouple.couple.brideParents}</div> */}
                                            <div className="font-m-r">{dataCouple.couple.brideChildTh}</div>
                                            <div className="font-m-r">{dataCouple.couple.brideFather}</div>
                                            <div className="font-m-r">&</div>
                                            <div className="font-m-r">{dataCouple.couple.brideMother}</div>
                                            <a target="_blank" rel="noopener noreferrer" href={dataCouple.couple.brideUrlIg}>
                                                <div className="row justify-content-center align-items-center font-m-r">
                                                    <InstagramOutlined style={{ fontSize: '15px', padding: '15px 4px' }} /> {dataCouple.couple.brideUsernameIg}
                                                </div>
                                            </a>
                                        </div>
                                    </Zoom>
                                </div>

                                <br /><br />
                                <div ref={eventRef} className="container-event">
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Save The Date</p>
                                        <div className="content-event">
                                            <p className="font-m-b txt-size-1rem">Holy Matrimony</p>
                                            <p className="font-m-b">{dataCouple.moment.holyMatrimonyDate}</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">{dataCouple.moment.holyMatrimonyTime}</p>

                                            <FontAwesomeIcon icon={faMap} />
                                            <p className="font-m-r">{dataCouple.location.holyMatrimony}</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">{dataCouple.location.holyMatrimonyDetails}</p>
                                        </div>
                                        <br /><br />
                                        <div className="add-google">
                                            <a target="_blank" rel="noopener noreferrer" href={dataCouple.moment.holyMatrimonyCalendar}>
                                                ADD TO GOOGLE CALENDAR
                                            </a>
                                        </div>

                                    </Zoom>
                                </div>

                                <br /><br />
                                <div className="container-event">
                                    <Zoom>
                                        <div className="content-event">
                                            <p className="font-m-b">The Wedding Day</p>
                                            <p className="font-m-b">{dataCouple.moment.fullDate}</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">{dataCouple.moment.receptionTime}</p>

                                            <FontAwesomeIcon icon={faMap} />
                                            <p className="font-m-r">{dataCouple.location.reception}</p>
                                            <div className="line"></div>
                                            <p className="font-m-r">{dataCouple.location.receptionDetails}</p>
                                        </div>
                                        <br /><br />
                                        <div className="add-google">
                                            <a target="_blank" rel="noopener noreferrer" href={dataCouple.moment.receptionCalendar}>
                                                ADD TO GOOGLE CALENDAR
                                            </a>
                                        </div>
                                    </Zoom>
                                </div>

                                <br /><br />
                                <div ref={galleryRef}></div>
                                <div className="container-gallery">
                                    <Zoom>
                                        <div className="txt-size-2rem font-a-b">
                                            Gallery
                                        </div>
                                    </Zoom>
                                    <Zoom>
                                        {/* <p className="txt-size-2rem font-a-b">Gallery</p> */}
                                        <LightGallery
                                            onInit={onInit}
                                            speed={500}
                                            plugins={[lgThumbnail, lgZoom]}
                                            elementClassNames="lg-custom-thumbnails"
                                        >
                                            <Zoom>
                                                <a className="gallery-item gallery-item--1" href={dataCouple.gallery.img9}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img9} data-src={dataCouple.gallery.img9} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--2" href={dataCouple.gallery.img12}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img12} data-src={dataCouple.gallery.img12} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--3" href={dataCouple.gallery.img24}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img24} data-src={dataCouple.gallery.img24} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--4" href={dataCouple.gallery.img10}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img10} data-src={dataCouple.gallery.img10} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--5" href={dataCouple.gallery.img2}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img2} data-src={dataCouple.gallery.img2} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--6" href={dataCouple.gallery.img6}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img6} data-src={dataCouple.gallery.img6} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--7" href={dataCouple.gallery.img8}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img8} data-src={dataCouple.gallery.img8} loading="lazy" />
                                                </a>
                                            </Zoom>
                                            <Zoom>
                                                <a className="gallery-item gallery-item--8" href={dataCouple.gallery.img13}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img13} data-src={dataCouple.gallery.img13} loading="lazy" />
                                                </a>
                                            </Zoom>

                                            <Zoom>
                                                <a className="gallery-item gallery-item--9" href={dataCouple.gallery.img30}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img30} data-src={dataCouple.gallery.img30} loading="lazy" />
                                                </a>
                                            </Zoom>

                                            <Zoom>
                                                <a className="gallery-item gallery-item--10-el" href={dataCouple.gallery.img7}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img7} data-src={dataCouple.gallery.img7} loading="lazy" />
                                                </a>
                                            </Zoom>

                                            <Zoom>
                                                <a className="gallery-item gallery-item--11-el" href={dataCouple.gallery.img29}>
                                                    <img className="gallery-img" alt={dataCouple.couple.fullName} src={dataCouple.gallery.img29} data-src={dataCouple.gallery.img29} loading="lazy" />
                                                </a>
                                            </Zoom>

                                        </LightGallery>
                                    </Zoom>
                                </div>

                                {/* <Zoom>
                                    <div className="container-text-video txt-size-2rem font-a-b">
                                        Live Wedding
                                    </div>
                                    <br /><br />
                                </Zoom>

                                <div className="container-video">
                                    <iframe
                                        title="Leonardo & Mira"
                                        className="video"
                                        src={dataCouple.youtube.liveStreaming}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen="allowFullScreen">
                                    </iframe>
                                </div> */}
                                <br /><br />

                                <div ref={quoteRef} className="container-slider">
                                    <Fade>
                                        <div className="container-slider-photo">
                                            <img
                                                className="slide-photo"
                                                src={dataCouple.gallery.img1}
                                                loading="lazy"
                                                alt="einvitation"
                                            />
                                        </div>
                                    </Fade>
                                </div>

                                <div className="container-quotes">
                                    <div className="container-image-quotes">
                                        <img
                                            className="image-quotes"
                                            src={jinggamuda2}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                    <Zoom>
                                        <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                                            "{dataCouple.couple.quotation2}"
                                        </div>
                                        <div className="container-text font-a txt-size-2rem" style={{ lineHeight: '30px' }}>
                                            {dataCouple.couple.quotation2desc}
                                        </div>
                                    </Zoom>
                                </div>

                                {/* <br />
                                <Zoom>
                                    {
                                        <OurStory
                                            data={dataCouple.story}
                                        />
                                    }
                                </Zoom> */}

                                {/* <div className="container-gift">
                                    <Zoom>
                                        <p className="txt-size-2rem dark-brown-txt font-a-b">Digital Gift</p>
                                    </Zoom>
                                    <Zoom>
                                        <p className="font-m-r">Your kind blessing can be sent to this information bellow</p>
                                    </Zoom>
                                    <Zoom>
                                        <div className="btn-gift txt-size-1rem">
                                            <button className="btn-gift-style" onClick={() => setOpenModal(!openModal)}>CLICK HERE</button>
                                        </div>
                                    </Zoom>
                                </div> */}

                                {
                                    openModal ?
                                        <Popup />
                                        : null
                                }
                                <br /><br /><br />
                                <div ref={wishRef} className="container-wishes">
                                    <Zoom>
                                        <p className="txt-size-2rem font-a-b">Wedding Wish</p>
                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <label className='font-m-r' style={{width: '100%', textAlign: 'left'}}>
                                                Name <span style={{color:'red'}}>*</span>
                                                <input value={name} type="text" maxLength="50" required onChange={(e) => setName(e.target.value)} />
                                            </label>

                                            <label className='font-m-r' style={{ width: '100%', textAlign: 'left' }}>
                                                Will you attend? <span style={{ color: 'red' }}>*</span> <br></br>
                                                <select required defaultValue={'0'} value={isAttend} onChange={(e) => setIsAttend(e.target.value)}>
                                                    <option value='0' disabled hidden>Choose</option>
                                                    <option value='no'>No</option>
                                                    <option value='yes1'>Yes, Holy Matrimony only</option>
                                                    <option value='yes2'>Yes, The Wedding Day only</option>
                                                    <option value='yes3'>Yes, I attend both</option>
                                                </select>
                                            </label>

                                            <label className='font-m-r' style={{ width: '100%', textAlign: 'left' }}>
                                                No. of Guests <span style={{ color: 'red' }}>*</span> <br></br>
                                                <select required defaultValue={'0'} value={person} onChange={(e) => setPerson(e.target.value)}>
                                                    <option value='0' disabled hidden>Choose</option>
                                                    <option value='1'>1</option>
                                                    <option value='2'>2</option>
                                                </select>
                                            </label>

                                            <label className='font-m-r' style={{ width: '100%', textAlign: 'left' }}>
                                                Wedding Wish <span style={{ color: 'red' }}>*</span>
                                                <textarea value={wishes} type="text" maxLength="500" rows="4" required onChange={(e) => setWishes(e.target.value)} />
                                            </label>

                                            <br></br><br></br>

                                            <div className="add-wishes">
                                                <button className="btn-wishes-style txt-size-1rem">
                                                    {
                                                        isLoading ? "Sending..." : "SEND WISH"
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </Zoom>
                                    <br /><br />
                                    <Zoom>
                                        {
                                            wishesList.length > 0 ?
                                                <div className="container-wishes-list">
                                                    {
                                                        wishesList.map((data, index) => (
                                                            <div key={index}>
                                                                <Fade>
                                                                    <div>
                                                                        <FontAwesomeIcon icon={faPaperPlane} />
                                                                    </div>
                                                                    <div className="content">
                                                                        <p className="name font-m-b txt-size-075rem">{data.name}</p>
                                                                        <p className="font-m-r txt-size-075rem">{data.wishes}</p>
                                                                    </div>
                                                                </Fade>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                : <div></div>
                                        }
                                    </Zoom>
                                </div>
                                
                                <br /><br />
                                <br /><br />
                                <div className="container-thankyou">
                                    <HeadShake>
                                        <div className="container-text-thankyou txt-size-2rem font-a-b">Thank You</div>
                                    </HeadShake>
                                    <Zoom>
                                        <div className="container-text-thankyou font-ar txt-size-35rem" style={{ lineHeight: '70px' }}>{dataCouple.couple.fullName}</div>
                                    </Zoom>
                                    <div className="container-image-thankyou">
                                        <img
                                            className="image-thankyou"
                                            src={jingga2}
                                            loading="lazy"
                                            alt="einvitation"
                                        />
                                    </div>
                                </div>
                                {/* Sampai sini */}
                                {/* <div className="font-m-r" style={{ display: 'none' }}>
                                    <AudioPlayer src={audioSrc} onPlay={playing} />
                                </div> */}

                                <a className="music" onClick={() => setPlaying(!playing)}>
                                    {
                                        playing ?
                                            <Fragment>
                                                <div className="font-m-r" style={{ display: 'none' }}>
                                                    <AudioPlayer src={audioSrc} onPlay={playing} />
                                                </div>
                                                <FontAwesomeIcon icon={faMusic} className="my-music" />
                                            </Fragment>
                                            :
                                            <FontAwesomeIcon icon={faVolumeMute} className="my-music" />
                                    }
                                </a>

                                <Suspense fallback={<div>Loading...</div>}>
                                    {/* <Footer /> */}
                                    <div id="footer-section-ultimate">
                                        <div className="row justify-content-center">
                                            <div className="font-m-r align-items-center">
                                                Made with <span><HeartFilled style={{ color: 'red', fontSize: '25px', padding: '0 5px' }} /></span> by
                                            </div>
                                            <div className="container-logo-image">
                                                <a href="https://www.einvitation.weboosh.id">
                                                    <img
                                                        className="img"
                                                        src={logoWhite}
                                                        loading="lazy"
                                                        alt="logo"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                        <a href="https://www.instagram.com/weboosh">
                                            <div className="row justify-content-center align-items-center" style={{ fontSize: '25px', paddingTop: '15px' }}><InstagramOutlined /></div>
                                        </a>
                                    </div>
                                </Suspense>
                                <div className="menu-sticky">
                                    <div className="container-menu txt-size-075rem font-m-r white-txt">
                                        <div onClick={() => onChangeRef("home")}>
                                            <img src={home} alt="Index" loading="lazy" className='color-img' />
                                            <div>HOME</div>
                                        </div>
                                        <div onClick={() => onChangeRef("couple")}>
                                            <img src={couple} alt="Index" loading="lazy" className='color-img' />
                                            <div>COUPLE</div>
                                        </div>
                                        <div onClick={() => onChangeRef("event")}>
                                            <img src={calender} alt="Index" loading="lazy" className='color-img' />
                                            <div>EVENT</div>
                                        </div>
                                        <div onClick={() => onChangeRef("gallery")}>
                                            <img src={gallery} alt="Index" loading="lazy" className='color-img' />
                                            <div>GALLERY</div>
                                        </div>
                                        <div onClick={() => onChangeRef("quote")}>
                                            <img src={bible} alt="Index" loading="lazy" className='color-img' />
                                            <div>VERSE</div>
                                        </div>
                                        <div onClick={() => onChangeRef("wish")}>
                                            <img src={wish} alt="Index" loading="lazy" className='color-img' />
                                            <div>WISH</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Fragment>
                        : null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    Ultimate: state.Ultimate
})
const mapDispatchToProps = {
    ...actions
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ultimate);
